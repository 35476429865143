import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'gatsby';

import useLocale from '../../../hooks/useLocale';

import Link from '/src/components/LocalizedLink';
import Layout from '../../../components/Layout';
import ForthcomingCourses from '../../../components/ForthcomingCourses';
import AplicationProcess from '../../../components/AplicationProcess';
import ReviewsSection from '../../../components/ReviewsSection';
import Faq from '../../../components/Faq';

import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../../../assets/images/svg/calendar.svg';
import iconClock from '../../../assets/images/svg/clock.svg';
import iconColumn from '../../../assets/images/trinity-cert-tesol-course-page/column.svg';
import iconTick from '../../../assets/images/svg/tick.svg';
import iconAccomodation from '../../../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../../../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../../../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../../../assets/images/student-support/Bureaucracy.svg';

import imgBenefit1 from '../../../assets/images/trinity-cert-tesol-course-page/Internationally Recognized Qualification (1).svg';
import imgBenefit2 from '../../../assets/images/trinity-cert-tesol-course-page/Practical Classroom Experience .svg';
import imgBenefit3 from '../../../assets/images/trinity-cert-tesol-course-page/Small Group Sizes  (1).svg';
import imgBenefit5 from '../../../assets/images/trinity-cert-tesol-course-page/Post-Grad Job Prospects 2.svg';
import imgReview from '../../../assets/images/online-trinity-course/Снимок экрана 2020-11-05 212101.png';
import iconQuotes from '../../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgDevelopingTeacherCourse from '../../../assets/images/other-popular-courses/Developing Teacher Course.png';
import imgTeachYoungLearners from '../../../assets/images/other-popular-courses/Teacher Young Learners (1).png';
import imgIntensiveSpanish from '../../../assets/images/other-popular-courses/Intensive Spanish Course (1).png';

import fileTrinityCertTESOLSyllabus from '../../../assets/files/CertTESOL-syllabus-Jan-2016.pdf';
import fileAssesmentCriteria from '../../../assets/files/Trinity CertTESOL assessment guidelines.pdf';

import '../../../assets/styles/pages/trinity-cert-tesol-course-page.scss';

const assesmentCriteria = `Download full assesment criteria <a href='${fileAssesmentCriteria}' target="_blank" class="red-link">(pdf)</a>`;
const esAssesmentCriteria = `Descarga los criterios de evaluación completos <a href='${fileAssesmentCriteria}' target="_blank" class="red-link">(pdf)</a>`;

// const cardData = [
//   {
//     course: 'Trinity CertTESOL',
//     from: '5 October 2020',
//     to: '30 October 2020',
//     price: '€1900',
//   },
// ];

// TODO: LOCALIZE FAQS
const week1 = `
<h5>Monday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Welcome and introductions to online study tools.</li>
    <li class='faq-list__item'>Introduction to teaching & course overview</li>
    <li class='faq-list__item'>Introduction to lesson planning (PPP model)</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Tuesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Classroom management</li>
    <li class='faq-list__item'>Lessons planning (TTT model)</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Wednesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Grammar review</li>
    <li class='faq-list__item'>Verb tense and aspect </li>
    <li class='faq-list__item'>Choral drilling</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Thursday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Lexis (1): Form, collocations</li>
    <li class='faq-list__item'>Using the board</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>                </ul>
<h5>Friday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Lexis (2): Presenting</li>
    <li class='faq-list__item'>Asking effective Concept Checking Questions (CCQs) </li>
    <li class='faq-list__item'>End of week review</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
`;
const week2 = `
<h5>Monday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Alternative lesson planning models</li>
    <li class='faq-list__item'>Meaning, Form, Pronunciation</li>
    <li class='faq-list__item'>Unknown Language Lesson</li>
</ul>
<h5>Tuesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Reception Skills: Reading</li>
    <li class='faq-list__item'>Cross-linguistic comparisons with Spanish</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Wednesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Reception Skills: Listening</li>
    <li class='faq-list__item'>Grammar: The passive voice</li>
    <li class='faq-list__item'>Emergent Language</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Thursday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Error correction</li>
    <li class='faq-list__item'>Learner Profile assignment preparation</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>                
</ul>
<h5>Friday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Pronunciation</li>
    <li class='faq-list__item'>Connected speech</li>
    <li class='faq-list__item'>Learner Profile interviews</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
`;
const week3 = `
<h5>Monday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Exploring authentic materials</li>
    <li class='faq-list__item'>Testing and assessment</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Tuesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Teaching kids</li>
    <li class='faq-list__item'>Productive skills: Speaking</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Wednesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Guided discovery</li>
    <li class='faq-list__item'>Grammar: relative clauses</li>
    <li class='faq-list__item'>Inductive v.s. Deductive learning</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Thursday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Materials Assignment preparation</li>
    <li class='faq-list__item'>Learner Profile assignment preparation</li>
</ul>
<h5>Friday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Productive skills: writing</li>
    <li class='faq-list__item'>Discourse analysis</li>
    <li class='faq-list__item'>Coherence and cohesion</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
`;
const week4 = `
<h5>Monday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Teaching exam preparation classes</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Tuesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>English for Specific Purposes (ESP)</li>
    <li class='faq-list__item'>Teaching methodologies</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Wednesday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Jobs / careers talk</li>
    <li class='faq-list__item'>Professional development</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>
</ul>
<h5>Thursday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Online teaching</li>
    <li class='faq-list__item'>Materials Assignment mock interview</li>
    <li class='faq-list__item'>Teaching practice, observations and feedback</li>                
</ul>
<h5>Friday</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Moderation</li>
</ul>
`;

const esWeek1 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Bienvenida e introducción a las herramientas de estudio en línea.</li>
    <li class='faq-list__item'>Introducción a la enseñanza y descripción general del curso.</li>
    <li class='faq-list__item'>Introducción a la planificación de lecciones (modelo PPP).</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Gestión del aula.</li>
    <li class='faq-list__item'>Planificación de lecciones (modelo TTT).</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Revisión gramatical.</li>
    <li class='faq-list__item'>Tiempo y aspecto verbal.</li>
    <li class='faq-list__item'>Repetición coral.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Léxico (1): Forma, colocaciones.</li>
    <li class='faq-list__item'>Uso del pizarrón.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>                </ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Léxico (2): Presentación.</li>
    <li class='faq-list__item'>Realización de preguntas efectivas de verificación de conceptos (CCQ).</li>
    <li class='faq-list__item'>Revisión de fin de semana.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
`;
const esWeek2 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Modelos alternativos de planificación de lecciones.</li>
    <li class='faq-list__item'>Significado, forma, pronunciación.</li>
    <li class='faq-list__item'>Lección de idioma desconocido.</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Habilidades de recepción: lectura.</li>
    <li class='faq-list__item'>Comparaciones interlingüísticas con el español.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Habilidades de recepción: escucha.</li>
    <li class='faq-list__item'>Gramática: la voz pasiva.</li>
    <li class='faq-list__item'>Lenguaje emergente.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Corrección de errores.</li>
    <li class='faq-list__item'>Preparación de la asignación de perfil del alumno.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Pronunciación.</li>
    <li class='faq-list__item'>Discurso conectado.</li>
    <li class='faq-list__item'>Entrevistas de perfil del alumno.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
`;
const esWeek3 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Exploración de materiales auténticos.</li>
    <li class='faq-list__item'>Evaluación y pruebas.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Enseñanza a niños.</li>
    <li class='faq-list__item'>Habilidades productivas: hablar.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Descubrimiento guiado.</li>
    <li class='faq-list__item'>Gramática: cláusulas relativas.</li>
    <li class='faq-list__item'>Aprendizaje inductivo vs. deductivo.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Preparación de la asignación de materiales.</li>
    <li class='faq-list__item'>Preparación de la asignación de perfil del alumno.</li>
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Habilidades productivas: escribir.</li>
    <li class='faq-list__item'>Análisis del discurso.</li>
    <li class='faq-list__item'>Coherencia y cohesión.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
`;
const esWeek4 = `
<h5>Lunes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Enseñanza de clases de preparación de exámenes.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Martes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Inglés con fines específicos (ESP).</li>
    <li class='faq-list__item'>Metodologías de enseñanza.</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y comentarios.</li>
</ul>
<h5>Miércoles</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Charla sobre trabajos/carreras</li>
    <li class='faq-list__item'>Desarrollo profesional</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y retroalimentación</li>
</ul>
<h5>Jueves</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Enseñanza en línea</li>
    <li class='faq-list__item'>Entrevista simulada de la tarea de materiales</li>
    <li class='faq-list__item'>Práctica de enseñanza, observaciones y retroalimentación</li>                
</ul>
<h5>Viernes</h5>
<ul class='faq-list'>
    <li class='faq-list__item'>Moderación</li>
</ul>
`;

const faq1 = `Does this course qualify me to teach English?`;
const faq1Answer = `Yes. The Trinity CertTESOL certificate, is one of few courses which are recognised by governments and schools internationally.`;
const faq2 = `How much will I get paid as an English teacher?`;
const faq2Answer = `The average rate of pay nowadays for new teachers is 15-17€/hour. Working for an academy full time (20 hours) you can expect to earn 1300€/month after tax. If you add on 2 private classes per week (it is easy to do more) you can increase that to 1600€/month."`;
const faq3 = `Do I need to speak Spanish to teach English in Spain?`;
const faq3Answer = `No. English is the only language used in the classroom and we want to show you how you can teach without using a word of the foreign language.`;
const faq4 = `How long will it take me to find a job?`;
const faq4Answer = `Normally two weeks of continued job searching. We run our job finding workshop early-on in the course so you can start looking for jobs before the end of the course.`;
const faq5 = `Do I need a visa?`;
const faq5Answer = `If you are a European Union citizen then you are free to work and study in any of the EU countries. If you are a non-EU citizen then you can stay up to three months as a tourist and you are free to study and do private classes during this time. If you would like to stay longer and obtain a work permit, the easiest way to do this is with a student visa, which will allow you to work up to 20 hours per week (which is full time for an English teacher). TEFL Iberia can process a student visa for you, for more detailed information please contact classes@ilabarcelona.com.`;
const faq6 = `What is the average cost of living in Spain?`;
const faq6Answer = `In Barcelona you can expect to pay 550-600€ for a quality double room in a shared flat in the city centre, then about 150€/week on food and entertainment – this money will stretch quite far as Barcelona can be very cheap compared to the UK / US / Canada / Australia.
<br /><br />Seville is even cheaper: a double room in a shared flat will cost about 350€ and you can expect to spend about 120€/week on food and entertainment.`;

const esFaq1 = `¿Este curso me califica para enseñar inglés?`;
const esFaq1Answer = `Sí. El certificado Trinity CertTESOL es uno de los pocos cursos reconocidos por gobiernos y escuelas internacionalmente.`;
const esFaq2 = `¿Cuánto voy a ganar como profesor/a de inglés?`;
const esFaq2Answer = `La tarifa media de pago hoy en día para nuevos profesores es de 15-17€/hora. Trabajando para una academia a tiempo completo (20 horas), puedes esperar ganar 1300€/mes después de impuestos. Si añades 2 clases privadas por semana (es fácil hacer más), puedes aumentar esto a 1600€/mes.`;
const esFaq3 = `¿Necesito hablar español para enseñar inglés en España?`;
const esFaq3Answer = `No. El inglés es el único idioma utilizado en el aula y queremos mostrarte cómo puedes enseñar sin usar una sola palabra del idioma extranjero.`;
const esFaq4 = `¿Cuánto tiempo me llevará encontrar un trabajo?`;
const esFaq4Answer = `Normalmente dos semanas de búsqueda continua de trabajo. Ejecutamos nuestro taller de búsqueda de empleo al comienzo del curso para que puedas comenzar a buscar trabajo antes de que finalice el curso.`;
const esFaq5 = `¿Necesito una visa?`;
const esFaq5Answer = `Si eres ciudadano de la Unión Europea, eres libre de trabajar y estudiar en cualquiera de los países de la UE. Si no eres ciudadano de la UE, puedes permanecer hasta tres meses como turista y eres libre de estudiar y hacer clases privadas durante este tiempo. Si deseas quedarte más tiempo y obtener un permiso de trabajo, la forma más fácil de hacerlo es con una visa de estudiante, que te permitirá trabajar hasta 20 horas por semana (que es tiempo completo para un profesor de inglés). TEFL Iberia puede procesar una visa de estudiante para ti, para obtener información más detallada, por favor contacta classes@ilabarcelona.com.`;
const esFaq6 = `¿Cuál es el costo promedio de vida en España?`;
const esFaq6Answer = `En Barcelona puedes esperar pagar 550-600€ por una habitación doble de calidad en un piso compartido en el centro de la ciudad, y luego unos 150€/semana en comida y ocio - este dinero se estira bastante ya que Barcelona puede ser muy barata en comparación con el Reino Unido / EE. UU. / Canadá / Australia.
<br /><br />Sevilla es aún más barata: una habitación doble en un piso compartido costará alrededor de 350€ y puedes esperar gastar alrededor de 120€/semana en comida y entretenimiento.
`;

const ActiveForthcomingCourses = ({ courseType, staticCourseTypeName, allCourses }) => {
  const intl = useIntl();
  const [isMore, setIsMore] = useState(false);

  const showLessDates =
    intl.formatMessage({ id: 'forthcomingCourses.active.showLess' }) || 'Show Less Dates';
  const lodaMoreDates =
    intl.formatMessage({ id: 'forthcomingCourses.active.loadMore' }) || 'Load More Dates';

  return (
    <div className={`inner-wrapper ${courseType === staticCourseTypeName ? 'active' : ''}`}>
      <ForthcomingCourses cardData={allCourses.slice(0, isMore ? allCourses.length : 4)} />
      {allCourses.length > 4 ? (
        <button
          onClick={() => setIsMore(!isMore)}
          className="c-btn c-btn--red-border load-more-dates"
        >
          {isMore ? showLessDates : lodaMoreDates}
          <IconArrowRightRed />
        </button>
      ) : null}
    </div>
  );
};

const TrinityCertTesolCourse = ({ data }) => {
  const [courseType, setCourseType] = useState('barcelona'); // 'barcelona' | 'seville'

  const allPrismicCourses = data.allPrismicCourses.edges;
  const allBarcelonaCourses = useMemo(
    () =>
      allPrismicCourses.filter(
        ({
          node: {
            data: { location },
          },
        }) => location === 'barcelona' || location === null
      ),
    [allPrismicCourses]
  );
  const allSevilleCourses = useMemo(
    () =>
      allPrismicCourses.filter(
        ({
          node: {
            data: { location },
          },
        }) => location === 'seville' || location === null
      ),
    [allPrismicCourses]
  );

  const { enLocale } = useLocale({ matchPathName: '/es/' });
  return (
    <Layout
      title={{ id: 'trinityCerttesol.seo.title' }}
      description={{ id: 'trinityCerttesol.seo.description' }}
    >
      <div className="trinity-cert-tesol-course-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="calendar" />
                    <span className="c-text-14">
                      <FormattedMessage id="trinityCerttesol.sec1.weeks" />
                    </span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14">
                      {' '}
                      <FormattedMessage id="trinityCerttesol.sec1.hours" />
                    </span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconColumn} alt="column" />
                    <span className="c-text-14">
                      {' '}
                      <FormattedMessage id="trinityCerttesol.sec1.cities" />
                    </span>
                  </div>
                </div>
                <h1 className="c-first-section__title">
                  {' '}
                  <FormattedMessage id="trinityCerttesol.sec1.title" />
                </h1>
                <p className="c-first-section__description">
                  <FormattedMessage id="trinityCerttesol.sec1.description" />
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  <FormattedMessage id="trinityCerttesol.sec2.title" />
                </h2>
                <p className="c-text-18 description second-section__l">
                  <FormattedMessage id="trinityCerttesol.sec2.description" />
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">
                    {' '}
                    <FormattedMessage id="trinityCerttesol.sec2.benefitsTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="trinityCerttesol.sec2.benefit1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="trinityCerttesol.sec2.benefit2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      {' '}
                      <FormattedMessage id="trinityCerttesol.sec2.benefit3" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      {' '}
                      <FormattedMessage id="trinityCerttesol.sec2.benefit4" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        {' '}
                        <FormattedMessage id="trinityCerttesol.sec2.ben1.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="trinityCerttesol.sec2.ben1.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        {' '}
                        <FormattedMessage id="trinityCerttesol.sec2.ben2.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="trinityCerttesol.sec2.ben2.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        {' '}
                        <FormattedMessage id="trinityCerttesol.sec2.ben3.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="trinityCerttesol.sec2.ben3.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        {' '}
                        <FormattedMessage id="trinityCerttesol.sec2.ben4.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="trinityCerttesol.sec2.ben4.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      <FormattedMessage id="trinityCerttesol.sec3.quotation" />
                      <br />
                      <strong>Erika Grandstaff</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">
                    <FormattedMessage id="trinityCerttesol.sec3.title" />
                  </h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit3" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit4" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit5" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit6" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit7" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit8" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit9" />
                      </p>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">
                      <FormattedMessage id="trinityCerttesol.sec3.fullPrice" />
                    </h3>
                    <h3 className="c-title-32">1650€</h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      <FormattedMessage id="trinityCerttesol.sec3.earlyBirdPrice" />{' '}
                      <span className="notion">
                        <FormattedMessage id="trinityCerttesol.sec3.payInFull" />
                      </span>
                    </h3>
                    <h3 className="c-title-32">1500€</h3>
                  </div>
                  <div className="block_r__btns">
                    <FormattedMessage id="url.apply.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          <FormattedMessage id="menu.applyNow" />
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <FormattedMessage id="url.tefl-iberia-brochure.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red-border">
                          <FormattedMessage id="homepage.sec6.downloadBrochure" />
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section courses-section">
          <div className="container">
            <h1 className="courses-section__title c-title-34">
              <FormattedMessage id="forthcomingCourses.title.upcoming" />
            </h1>
            <div className="courses-section__btns">
              <button
                onClick={() => setCourseType('barcelona')}
                className={`btn ${courseType === 'barcelona' ? 'btn--active' : ''}`}
              >
                Barcelona
              </button>
              <button
                onClick={() => setCourseType('seville')}
                className={`btn ${courseType === 'seville' ? 'btn--active' : ''}`}
              >
                Seville
              </button>
            </div>
            <ActiveForthcomingCourses
              courseType={courseType}
              staticCourseTypeName={'barcelona'}
              allCourses={allBarcelonaCourses}
            />
            <ActiveForthcomingCourses
              courseType={courseType}
              staticCourseTypeName={'seville'}
              allCourses={allSevilleCourses}
            />
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section">
          <AplicationProcess />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="trinityCerttesol.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="sform.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">
                  <FormattedMessage id="trinityCerttesol.sec8.title" />
                </h3>
                <p className="c-text-18">
                  <FormattedMessage
                    id="trinityCerttesol.sec8.description"
                    values={{
                      accent: (chunk) => <span className="text-fw-600">{chunk}</span>,
                    }}
                  />
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.title" />
                  </h4>
                  <p className="c-text-16">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.description" />
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                  >
                    <FormattedMessage id="btn.takeEnglisAssessment" />
                    <IconArrowRightRed />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Faq
                  title={enLocale ? 'Week 1' : 'Semana 1'}
                  description={enLocale ? week1 : esWeek1}
                />
                <Faq
                  title={enLocale ? 'Week 2' : 'Semana 2'}
                  description={enLocale ? week2 : esWeek2}
                />
                <Faq
                  title={enLocale ? 'Week 3' : 'Semana 3'}
                  description={enLocale ? week3 : esWeek3}
                />
                <Faq
                  title={enLocale ? 'Week 4' : 'Semana 4'}
                  description={enLocale ? week4 : esWeek4}
                />
                <Faq
                  title={enLocale ? 'Assesment Criteria' : 'Criterios de evaluación'}
                  description={enLocale ? assesmentCriteria : esAssesmentCriteria}
                />
                <a
                  target="_blank"
                  href={fileTrinityCertTESOLSyllabus}
                  className="c-btn c-btn--red mt-50"
                >
                  <FormattedMessage id="btn.downloadTrinitySyllabus" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">
                <FormattedMessage id="common.faqs" />
              </h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq1 : esFaq1}
                  description={enLocale ? faq1Answer : esFaq1Answer}
                />
                <Faq
                  title={enLocale ? faq2 : esFaq2}
                  description={enLocale ? faq2Answer : esFaq2Answer}
                />
                <Faq
                  title={enLocale ? faq3 : esFaq3}
                  description={enLocale ? faq3Answer : esFaq3Answer}
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq4 : esFaq4}
                  description={enLocale ? faq4Answer : esFaq4Answer}
                />
                <Faq
                  title={enLocale ? faq5 : esFaq5}
                  description={enLocale ? faq5Answer : esFaq5Answer}
                />
                <Faq
                  title={enLocale ? faq6 : esFaq6}
                  description={enLocale ? faq6Answer : esFaq6Answer}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage
                id="trinityCerttesol.sec11.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="trinityCerttesol.sec11.description" />
            </p>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.developing-teacher-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgDevelopingTeacherCourse}
                        alt="Developing Teacher Course"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">120€ </span>
                        /<FormattedMessage id="trinityCerttesol.sec11.month" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="trinityCerttesol.sec11.course1" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.teaching-kids-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTeachYoungLearners}
                        alt="Teaching Young Learners"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /<FormattedMessage id="trinityCerttesol.sec11.days" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="trinityCerttesol.sec11.course2" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.tefl-spanish-course-barcelona">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgIntensiveSpanish}
                        alt="imgIntensiveSpanish"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /<FormattedMessage id="trinityCerttesol.sec11.month" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="trinityCerttesol.sec11.course3" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "trinity-cert-tesol-course" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
            location
          }
        }
      }
    }
  }
`;

export default TrinityCertTesolCourse;
